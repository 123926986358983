import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
  inject
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  NgbNav,
  NgbNavChangeEvent,
  NgbNavItem,
  NgbNavLink
} from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import * as fromAppState from 'admin/+state';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-tables-propertysearcher',
  templateUrl: './propertysearcher.component.html',
  styleUrls: ['./propertysearcher.component.scss'],
  imports: [NgbNav, RouterOutlet, NgbNavItem, NgbNavLink, TranslateModule],
  standalone: true
})
export class PropertySearcherComponent implements AfterViewInit {
  private store = inject<Store<fromAppState.AppState>>(Store);
  private cdref = inject(ChangeDetectorRef);
  private route = inject(ActivatedRoute);

  @ViewChild('nav') nav: NgbNav;

  ngAfterViewInit(): void {
    if (this.route?.firstChild) {
      this.route.firstChild.url
        .pipe(untilDestroyed(this))
        .subscribe(url => this.nav?.select(url[0].path));
    } else {
      this.store.dispatch(
        new fromBaseState.Go({ path: ['tables/landlord/customers'] })
      );
    }
    this.cdref.detectChanges();
  }

  public onTabChange($event: NgbNavChangeEvent) {
    this.store.dispatch(
      new fromBaseState.Go({
        path: ['tables/property-searcher', $event.nextId]
      })
    );
  }
}
