<div class="container p-3">
  <div class="row">
    <div class="col-12">
      <nav
        class="nav-tabs"
        #nav="ngbNav"
        ngbNav
        (navChange)="onTabChange($event)"
      >
        <ng-container ngbNavItem="users">
          <a ngbNavLink>{{ 'table.users_l' | translate }}</a>
        </ng-container>
      </nav>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
