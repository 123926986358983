<div class="container container--with-spacing">
  <div class="row">
    <div class="col-12">
      <div class="table-header d-flex justify-content-between">
        <h1 class="title-xl">{{ 'navigation.tables_l' | translate }}</h1>
        <app-button (clickEvent)="onCreateLandlord()">
          {{ 'tables.create_landlord_a' | translate }}
        </app-button>
      </div>
      <div class="card-deck">
        <div class="card col-12 p-0 mt20 border-0">
          <div class="card-header p-0 bg-white border-0">
            <nav
              class="nav-tabs"
              #nav="ngbNav"
              ngbNav
              (navChange)="onTabChange($event)"
            >
              <ng-container ngbNavItem="landlord">
                <a ngbNavLink>{{ 'table.landlord_l' | translate }}</a>
              </ng-container>
              <ng-container ngbNavItem="property-searcher">
                <a ngbNavLink>{{ 'table.property_searcher_l' | translate }}</a>
              </ng-container>
            </nav>
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
