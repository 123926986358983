import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DateComponent,
  FormFieldComponent,
  FormFieldLabelComponent,
  ModalComponent,
  ModalContentComponent,
  ModalFooterComponent
} from 'libs/components/legacy';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from 'libs/components/atoms';

@Component({
  selector: 'app-deactivate-product-renew-modal',
  templateUrl: './deactivate-product-renew-modal.component.html',
  styleUrls: ['./deactivate-product-renew-modal.component.scss'],
  imports: [
    ModalComponent,
    ModalContentComponent,
    TranslateModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    DateComponent,
    FormsModule,
    ModalFooterComponent,
    ButtonComponent
  ],
  standalone: true
})
export class DeactivateProductRenewModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public dueDate: string;

  okAction() {
    this.ngbActiveModal.close(this.dueDate);
  }

  cancelAction() {
    this.ngbActiveModal.dismiss();
  }
}
