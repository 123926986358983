import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  Addon,
  AddonType,
  Customer,
  Pagination,
  PriceTypes,
  Product,
  ProductOverview
} from '@ui/shared/models';
import { AddonsHelperService } from 'libs/products';
import { ActionState } from 'libs/state-utils';
import * as fromBaseState from 'libs/infrastructure/base-state';

import { DeactivateProductRenewModalComponent } from 'admin/screens/tables/components/landlord/components/disable-customer/deactivate-product-renew-modal.component';
import { NavService } from 'admin/screens/tables/service/nav.service';
import * as fromAppState from 'admin/+state';
import { deleteCustomerConfirmation } from 'admin/config';
import { Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import {
  AppInputDirective,
  FormFieldComponent,
  LoadingSpinnerComponent,
  ModalService,
  NoContentComponent,
  PaginationComponent,
  SelectComponent
} from 'libs/components/legacy';
import { AutofocusDirective } from 'libs/directives';
import { ButtonComponent } from 'libs/components/atoms';
import { AsyncPipe, CurrencyPipe, PercentPipe } from '@angular/common';
import {
  ContextMenuComponent,
  ContextMenuItemComponent
} from 'libs/components/molecules';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionDirective,
  NgbAccordionHeader,
  NgbAccordionItem
} from '@ng-bootstrap/ng-bootstrap';
import { DateTimePipe } from 'libs/pipes';

const SEARCH_OPTIONS = [
  { name: 'search.customer_id_l', value: 'CUSTOMER_ID' }
  // { name: 'search.landlords_l', value: 'NAME' }
];

@UntilDestroy()
@Component({
  selector: 'app-tables-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  imports: [
    FormFieldComponent,
    SelectComponent,
    ReactiveFormsModule,
    AppInputDirective,
    AutofocusDirective,
    TranslateModule,
    ButtonComponent,
    AsyncPipe,
    ContextMenuComponent,
    ContextMenuItemComponent,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionBody,
    DateTimePipe,
    PercentPipe,
    CurrencyPipe,
    LoadingSpinnerComponent,
    NoContentComponent,
    PaginationComponent
  ],
  standalone: true
})
export class CustomersComponent implements OnInit {
  private store = inject<Store<fromAppState.AppState>>(Store);
  private navService = inject(NavService);
  private formBuilder = inject(FormBuilder);
  private translate = inject(TranslateService);
  private route = inject(ActivatedRoute);
  private addonsHelperService = inject(AddonsHelperService);
  private modalService = inject(ModalService);

  public customers: Customer[];
  public pagination$: Observable<Pagination>;
  public customerProducts: { [key: string]: ProductOverview };

  // Customer landlordProducts array excluding the product discount, to display the addons with their discount only
  public customerProductsWithoutDiscount: { [key: string]: Product };

  public isLoadingProducts$: Observable<ActionState>;
  public isLoading$: Observable<ActionState>;
  public searchForm: FormGroup;
  public searchOptions;

  public pageSize = 10;
  public page = 0;
  public headerArray = [
    'table.header_customer_id_l',
    'table.header_customer_name_l',
    'table.header_customer_admin_id_l',
    'table.header_customer_admin_email_l'
  ];

  readonly periodsMap = {
    '100000': 'general.monthly_l',
    '100001': 'general.yearly_l'
  };

  public totalPrice = 0;
  public totalPricePreDiscount = 0;
  public selectedCustomer: Customer = {};
  public customerId: string;

  ngOnInit() {
    this.isLoading$ = this.store.select(fromAppState.getLandlordActionState);
    this.isLoadingProducts$ = this.store.select(
      fromAppState.getLandlordProductsActionState
    );
    this.pagination$ = this.store.select(fromAppState.getLandlordPagination);
    this.customerId = this.route.snapshot.queryParams.customerId || null;
    this.customerId
      ? this.store.dispatch(new fromAppState.FetchCustomerById(this.customerId))
      : this.store.dispatch(
          new fromAppState.FetchCustomers({
            page: this.page,
            size: this.pageSize
          })
        );

    this.store
      .select(fromAppState.getLandlordActionState)
      .pipe(
        filter(state => !state.pending),
        switchMap(() => this.store.select(fromAppState.getCustomersData)),
        untilDestroyed(this)
      )
      .subscribe(customers => (this.customers = customers));

    this.isLoadingProducts$
      .pipe(
        filter(state => !state.pending),
        switchMap(() => this.store.select(fromAppState.getLandlordProducts)),
        untilDestroyed(this)
      )
      .subscribe(product => {
        this.customerProducts = product;
      });

    this.searchForm = this.formBuilder.group({
      searchValue: '',
      searchType: 'CUSTOMER_ID'
    });

    this.searchOptions = SEARCH_OPTIONS.map(option => ({
      name: this.translate.instant(option.name),
      value: option.value
    }));

    if (this.route.snapshot.queryParams.customerId) {
      this.onSearch({
        searchType: 'CUSTOMER_ID',
        searchValue: this.route.snapshot.queryParams.customerId
      });
    }
  }

  public agentsCount(addon: Addon): number {
    return addon.amounts.renew + addon.amounts.expire;
  }

  public onPageChange(page: number) {
    this.store.dispatch(
      new fromAppState.FetchCustomers({ page: page - 1, size: this.pageSize })
    );
  }

  public onGetInvoicesByCustomer(id: string) {
    this.navService.changeTab('invoices');
    this.store.dispatch(
      new fromBaseState.Go({
        path: ['tables/landlord', 'invoices'],
        extras: { queryParams: { customerId: id } }
      })
    );
  }

  public onSearch(searchForm: any) {
    if (searchForm.searchType === 'CUSTOMER_ID') {
      if (searchForm.searchValue === '') {
        this.store.dispatch(
          new fromAppState.FetchCustomers({
            page: this.page,
            size: this.pageSize
          })
        );
      } else {
        this.store.dispatch(
          new fromAppState.FetchCustomerById(searchForm.searchValue)
        );
      }
    }
  }

  public getAddonDiscount(customer: Customer, id: string) {
    if (!customer?.addonDiscounts?.length) return;
    return customer.addonDiscounts.find(
      addonDiscount => addonDiscount.addonId === id
    );
  }

  public loadAddonProductPrice(customer: Customer) {
    this.isLoadingProducts$
      .pipe(
        filter(state => !state.pending),
        take(1)
      )
      .subscribe(() => {
        this.selectedCustomer = customer;
        this.store.dispatch(new fromAppState.LoadCustomerProduct(customer.id));
      });
  }

  public getAddonPrices(
    customerId: string,
    addon: Addon,
    postDiscount = false
  ) {
    const selectedProduct = this.customerProducts[customerId];
    if (!selectedProduct) return;
    const prices = selectedProduct.addons.find(a => a.id === addon.id).price;
    const productId = selectedProduct.product.id;
    const count =
      selectedProduct.addons.find(a => a.type === AddonType.AGENT).amounts
        .renew +
      selectedProduct.addons.find(a => a.type === AddonType.AGENT).amounts
        .expire;

    if (postDiscount) {
      if (productId === '100001') {
        if (this.isAgent(addon.type)) {
          return prices.postDiscountYearlyPriceNet * (count - 1);
        }
        return prices.postDiscountYearlyPriceNet;
      }

      if (this.isAgent(addon.type)) {
        return prices.postDiscountMonthlyPriceNet * (count - 1);
      }
      return prices.postDiscountMonthlyPriceNet;
    }

    if (productId === '100001') {
      if (this.isAgent(addon.type)) {
        return prices.yearlyPriceNet * (count - 1);
      }
      return prices.yearlyPriceNet;
    }
    if (this.isAgent(addon.type)) {
      return prices.monthlyPriceNet * (count - 1);
    }
    return prices.monthlyPriceNet;
  }

  public getCustomerAddons(product: ProductOverview): Addon[] {
    this.calculateTotalPrice(
      product.addons,
      product.product.subscriptionPeriod === 'YEARLY'
    );
    return product.addons;
  }

  public isAgent(addonType: string) {
    return addonType === AddonType.AGENT;
  }

  public calculateTotalPrice(addons: Addon[], yearly = false) {
    this.totalPrice = this.addonsHelperService.getPrice(
      addons,
      null,
      PriceTypes.NET,
      true,
      yearly
    );
    this.totalPricePreDiscount = this.addonsHelperService.getPrice(
      addons,
      null,
      PriceTypes.NET,
      false,
      yearly
    );
  }

  public deactivateProductRenew(id: string) {
    this.modalService
      .open<DeactivateProductRenewModalComponent>(
        DeactivateProductRenewModalComponent
      )
      .onClose()
      .subscribe(dueDate =>
        this.store.dispatch(
          new fromAppState.DeactivateProductRenew(id, dueDate)
        )
      );
  }

  public enableExternalPricing(id: string, enable: boolean) {
    this.store.dispatch(new fromAppState.EnableExternalPricing(id, enable));
  }

  public deleteCustomer(id: string) {
    this.modalService
      .openConfirmation({
        data: { ...deleteCustomerConfirmation }
      })
      .onClose()
      .subscribe(() =>
        this.store.dispatch(new fromAppState.DeleteCustomers(id))
      );
  }

  public onAdminId(id: string) {
    this.store.dispatch(
      new fromBaseState.Go({
        path: [`landlord/${id}/edit/authentication`]
      })
    );
  }

  public onAdminEmail(email: string) {
    this.navService.changeTab('users');
    this.store.dispatch(
      new fromBaseState.Go({
        path: ['tables/landlord/users'],
        extras: { queryParams: { email } }
      })
    );
  }
}
