<app-modal>
  <div class="modal-xs">
    <app-modal-content>
      <div class="modal-body deactivate-product-renew-modal__body">
        <p class="title-m deactivate-product-renew-modal__title">
          {{ 'customer.deactivate_product_renew_l' | translate }}
        </p>

        <div class="due-date">
          <app-form-field>
            <app-form-field-label class="due-date__label">{{
              'customer.deactivate_product_renew_due_date_l' | translate
            }}</app-form-field-label>
            <app-date [(ngModel)]="dueDate"></app-date>
          </app-form-field>
          <p class="default-s explanation__message">
            {{ 'input_field_not_mandatory_m' | translate }}
          </p>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer deactivate-product-renew-modal__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="cancelAction()">{{
          'general.cancel_a' | translate
        }}</app-button>
        <app-button (clickEvent)="okAction()">{{
          'general.ok' | translate
        }}</app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
