import { Routes } from '@angular/router';

import { TablesComponent } from './tables.component';
import { LandlordComponent } from './components/landlord/landlord.component';
import { UsersComponent as LandlordUsersComponent } from './components/landlord/components/users/users.component';
import { PropertySearcherComponent } from './components/propertysearcher/propertysearcher.component';
import { CustomersComponent } from './components/landlord/components/customers/customers.component';
import { InvoicesComponent } from './components/landlord/components/invoices/invoices.component';
import { UsersComponent as PropertySearcherUsersComponent } from './components/propertysearcher/components/users/users.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: TablesComponent,
    children: [
      {
        path: '',
        redirectTo: 'landlord',
        pathMatch: 'full'
      },
      {
        path: 'landlord',
        component: LandlordComponent,
        children: [
          {
            path: '',
            redirectTo: 'customers',
            pathMatch: 'full'
          },
          {
            path: 'users',
            component: LandlordUsersComponent
          },
          {
            path: 'customers',
            component: CustomersComponent
          },
          {
            path: 'invoices',
            component: InvoicesComponent
          }
        ]
      },
      {
        path: 'property-searcher',
        component: PropertySearcherComponent,
        children: [
          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full'
          },
          {
            path: 'users',
            component: PropertySearcherUsersComponent
          }
        ]
      }
    ]
  }
];
