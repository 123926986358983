<div class="pt-3 pb-3 d-flex">
  <form [formGroup]="searchForm" class="col-12 p-0 d-flex">
    <div class="col-4 col-md-2 p0">
      <app-form-field>
        <app-select
          [items]="searchOptions"
          formControlName="searchType"
        ></app-select>
      </app-form-field>
    </div>
    <div class="col-4 col-md-3 p0">
      <app-form-field>
        <input
          appInput
          type="text"
          appAutofocus
          placeholder="{{ 'search.input_placeholder_l' | translate }}"
          class="form-control"
          formControlName="searchValue"
        />
        <app-button
          class="search-btn pt-0"
          [type]="'context-menu'"
          [elevationHoverEffect]="false"
          (clickEvent)="onSearch(searchForm)"
          [iconLeft]="'search'"
          [buttonType]="'submit'"
        >
        </app-button>
      </app-form-field>
    </div>
  </form>
</div>

@if (!(isLoading$ | async)?.pending) {
  @if ((invoices$ | async) && (invoices$ | async)?.length) {
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          @for (header of headerArray; track header) {
            <th scope="col">{{ header | translate }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (invoice of invoices$ | async; track invoice; let i = $index) {
          <tr>
            <th scope="col">
              {{
                i + pageSize * ((pagination$ | async)?.page - 1) + 1 + pageSize
              }}
            </th>
            <td>{{ invoice?.customer.id }}</td>
            <td>{{ invoice?.customer.name || '-' }}</td>
            <td>{{ invoice?.price }}</td>
            <td>
              <img
                src="/assets/images/icons/{{
                  invoice?.cancellation ? 'icon-yes.svg' : 'icon-no.svg'
                }}"
              />
            </td>
            <td>{{ invoice?.invoiceId }}</td>
            <td>
              <app-context-menu>
                <div menu-content>
                  <app-context-menu-item
                    (clickEvent)="downloadInvoice(invoice.invoiceId)"
                  >
                    {{ 'general.download_l' | translate }}
                  </app-context-menu-item>
                  @if (!invoice?.cancellation) {
                    <app-context-menu-item
                      (clickEvent)="cancelInvoice(invoice.invoiceId)"
                    >
                      {{ 'general.cancel_invoice_l' | translate }}
                    </app-context-menu-item>
                  }
                  @if (invoice?.cancellation) {
                    <app-context-menu-item
                      (clickEvent)="downloadCancelledInvoice(invoice.invoiceId)"
                    >
                      {{
                        'general.download_cancellation_invoice_l' | translate
                      }}
                    </app-context-menu-item>
                  }
                </div>
              </app-context-menu>
            </td>
          </tr>
        }
      </tbody>
    </table>
  } @else {
    <app-no-content
      headline="general.no_data_headline_l"
      message="general.no_data_message_l"
    ></app-no-content>
  }
} @else {
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
}

<ng-template #loading>
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>

<div class="d-flex justify-content-between p-2">
  <app-pagination
    [page]="(pagination$ | async)?.page + 1"
    [pageSize]="pageSize"
    [collectionSize]="(pagination$ | async)?.totalElements"
    (pageChange)="onPageChange($event)"
  >
  </app-pagination>
</div>
