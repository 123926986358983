<div class="pt-3 pb-3 d-flex">
  <form [formGroup]="searchForm" class="col-12 p-0 d-flex">
    <div class="col-4 col-md-3 p0">
      <app-form-field>
        <input
          appInput
          type="text"
          appAutofocus
          placeholder="{{ 'search.input_placeholder_l' | translate }}"
          class="form-control"
          formControlName="searchValue"
        />
        <app-button
          class="search-btn"
          [type]="'context-menu'"
          [elevationHoverEffect]="false"
          (clickEvent)="onSearch()"
          [iconLeft]="'search'"
          [buttonType]="'submit'"
        >
        </app-button>
      </app-form-field>
    </div>
  </form>
</div>
@if ((isLoading$ | async) === false) {
  @if ((users$ | async)?.length) {
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          @for (header of headerArray; track header) {
            <th
              scope="col"
              ngbTooltip="{{
                header === 'Active'
                  ? ('table.ps_active_tooltip_m' | translate)
                  : null
              }}"
            >
              {{ header | translate }}
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @for (user of users$ | async; track user; let i = $index) {
          <tr>
            <th scope="col">
              {{
                i + pageSize * ((pagination$ | async)?.page - 1) + 1 + pageSize
              }}
            </th>
            <td>{{ user?.id }}</td>
            <td>{{ (user?.lastLogin | appDateTime) || '-' }}</td>
            <td>{{ user?.email }}</td>
            <td>{{ user?.type }}</td>
            <td>
              <img
                src="/assets/images/icons/{{
                  user?.deleted
                    ? 'icon-bin.svg'
                    : user?.searchUntil > currentDate
                      ? 'icon-checkbox-green.svg'
                      : 'icon-no.svg'
                }}"
              />
            </td>
            <td>
              @if (!user?.deleted) {
                <app-context-menu>
                  <div menu-content>
                    <app-context-menu-item
                      (clickEvent)="activatePropertySearcher(user?.email)"
                    >
                      {{ 'table.ps_activate_l' | translate }}
                    </app-context-menu-item>
                    <app-context-menu-item
                      (clickEvent)="impersonateUser(user?.email)"
                    >
                      {{ 'table.login_as_user_a' | translate }}
                    </app-context-menu-item>
                    <app-context-menu-item
                      (clickEvent)="deletePropertySearcher(user?.id)"
                    >
                      {{ 'table.ps_delete_l' | translate }}
                    </app-context-menu-item>
                  </div>
                </app-context-menu>
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  } @else {
    <app-no-content
      headline="general.no_data_headline_l"
      message="general.no_data_message_l"
    ></app-no-content>
  }
} @else {
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
}

<ng-template #loading>
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>

<div class="d-flex justify-content-between p-2">
  <app-pagination
    [page]="(pagination$ | async)?.page + 1"
    [pageSize]="pageSize"
    [collectionSize]="(pagination$ | async)?.totalElements"
    (pageChange)="onPageChange($event)"
  >
  </app-pagination>
</div>
