import { Injectable } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NavService {
  private _nav: NgbNav;

  get nav() {
    return this._nav;
  }
  public init(nav: NgbNav) {
    this._nav = nav;
  }

  public changeTab(tab: string) {
    if (!this._nav) return;
    this._nav.select(tab);
  }
}
