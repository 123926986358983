import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { provideEffects } from '@ngrx/effects';
import { effects } from 'admin/+state';
import { ROUTES } from './tables.routes';
import { NavService } from './service/nav.service';

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  providers: [NavService, provideEffects(effects)]
})
export class TablesModule {}
