<div class="pt-3 pb-3 d-flex">
  <form [formGroup]="searchForm" class="col-12 p-0 d-flex">
    <div class="col-4 col-md-2 p0">
      <app-form-field>
        <app-select
          [items]="searchOptions"
          formControlName="searchType"
        ></app-select>
      </app-form-field>
    </div>
    <div class="col-4 col-md-3 p0">
      <app-form-field>
        <input
          appInput
          type="text"
          appAutofocus
          placeholder="{{ 'search.input_placeholder_l' | translate }}"
          class="form-control"
          formControlName="searchValue"
        />
        <app-button
          class="search-btn"
          [type]="'context-menu'"
          [elevationHoverEffect]="false"
          (clickEvent)="onSearch(searchForm.value)"
          [iconLeft]="'search'"
          [buttonType]="'submit'"
        >
        </app-button>
      </app-form-field>
    </div>
  </form>
</div>

@if (!(isLoading$ | async)?.pending) {
  @if (customers?.length) {
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          @for (header of headerArray; track header) {
            <th scope="col">{{ header | translate }}</th>
          }
        </tr>
      </thead>
      <tbody>
        @for (customer of customers; track customer; let i = $index) {
          <tr>
            <th scope="col">
              {{
                i + pageSize * ((pagination$ | async)?.page - 1) + 1 + pageSize
              }}
            </th>
            <td>
              {{ customer?.id }}
              @if (customer.deleted) {
                <span class="customer-deleted">{{
                  'table.customer_deleted_l' | translate
                }}</span>
              }
            </td>
            <td>{{ customer?.name || '-' }}</td>
            <td>
              @if (customer?.adminId) {
                <a
                  class="customer-admin-id__link"
                  (click)="onAdminId(customer?.adminId)"
                >
                  {{ customer?.adminId }}
                </a>
              } @else {
                -
              }
            </td>
            <td>
              @if (customer?.adminEmail) {
                <a
                  class="customer-admin-email__link"
                  (click)="onAdminEmail(customer?.adminEmail)"
                  >{{ customer?.adminEmail }}</a
                >
              } @else {
                -
              }
            </td>
            <td>
              <app-context-menu>
                <div menu-content>
                  <app-context-menu-item
                    (clickEvent)="onGetInvoicesByCustomer(customer.id)"
                  >
                    {{
                      'search.load_all_invoices_of_specific_landlord_a'
                        | translate
                    }}
                  </app-context-menu-item>
                  <app-context-menu-item
                    (clickEvent)="deactivateProductRenew(customer.id)"
                  >
                    {{ 'table.deactivate_product_renew_a' | translate }}
                  </app-context-menu-item>
                  <app-context-menu-item
                    (clickEvent)="
                      enableExternalPricing(
                        customer.id,
                        !customer.externalPricing
                      )
                    "
                  >
                    {{
                      (customer.externalPricing
                        ? 'table.disable_external_pricing_a'
                        : 'table.enable_external_pricing_a'
                      ) | translate
                    }}
                  </app-context-menu-item>
                  <app-context-menu-item
                    [disabled]="!!customer.deleted"
                    (clickEvent)="deleteCustomer(customer.id)"
                  >
                    {{ 'table.delete_customer_a' | translate }}
                  </app-context-menu-item>
                </div>
              </app-context-menu>
            </td>
          </tr>
          @if (customer?.hasActiveProduct) {
            <tr>
              <td colspan="10" class="customer-products">
                <div ngbAccordion [closeOthers]="true">
                  <div
                    ngbAccordionItem
                    (shown)="loadAddonProductPrice(customer)"
                  >
                    <div ngbAccordionHeader>
                      <button ngbAccordionButton>Produkte</button>
                    </div>
                    <div ngbAccordionCollapse>
                      <div ngbAccordionBody>
                        <ng-template>
                          @if (
                            !(isLoadingProducts$ | async)?.pending &&
                            customerProducts[customer.id]
                          ) {
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">
                                    {{
                                      'customer.addon_or_product_l' | translate
                                    }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      'customer.active_discount_l' | translate
                                    }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      'customer.active_discount_end_date_l'
                                        | translate
                                    }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      'landlordProducts.price_without_discount_l'
                                        | translate
                                    }}
                                  </th>
                                  <th scope="col">
                                    {{
                                      'landlordProducts.price_with_discount_l'
                                        | translate
                                    }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                @for (
                                  addon of getCustomerAddons(
                                    customerProducts[customer.id]
                                  );
                                  track addon
                                ) {
                                  <tr>
                                    <td>
                                      @if (isAgent(addon.type)) {
                                        <span>
                                          {{
                                            addon.amounts.renew +
                                              addon.amounts.expire
                                          }}
                                          {{
                                            (agentsCount === 1
                                              ? 'general.agent_l'
                                              : 'general.agents_l'
                                            ) | translate
                                          }}
                                          @if (agentsCount > 1) {
                                            <span>{{
                                              'general.free_agent_l' | translate
                                            }}</span>
                                          }
                                        </span>
                                      } @else {
                                        {{ addon.name | translate }}
                                      }
                                    </td>
                                    <td>
                                      {{
                                        addon?.discount?.value
                                          | percent: '1.0-5'
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        addon?.discount?.endDate | appDateTime
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        getAddonPrices(customer.id, addon)
                                          | currency: 'EUR'
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        getAddonPrices(customer.id, addon, true)
                                          | currency: 'EUR'
                                      }}
                                    </td>
                                  </tr>
                                }
                                <tr class="product__row">
                                  <td>
                                    {{
                                      periodsMap[
                                        customerProducts[customer.id].product.id
                                      ] | translate
                                    }}<br />
                                    {{ 'general.next_payment_l ' | translate }}:
                                    {{
                                      customer?.product?.dueDate | appDateTime
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      (customerProducts[customer.id]?.discount
                                        ?.value | percent: '1.0-5') || ''
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      (customerProducts[customer.id]?.discount
                                        ?.endDate | appDateTime) || ''
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      totalPricePreDiscount | currency: 'EUR'
                                    }}
                                  </td>
                                  <td>{{ totalPrice | currency: 'EUR' }}</td>
                                </tr>
                              </tbody>
                            </table>
                          } @else {
                            <div class="loading-area">
                              <app-loading-spinner></app-loading-spinner>
                            </div>
                          }
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          }
        }
      </tbody>
    </table>
  } @else {
    <app-no-content
      headline="general.no_data_headline_l"
      message="general.no_data_message_l"
    ></app-no-content>
  }
} @else {
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
}

<ng-template #loading>
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>

<div class="d-flex justify-content-between p-2">
  <app-pagination
    [page]="(pagination$ | async)?.page + 1"
    [pageSize]="pageSize"
    [collectionSize]="(pagination$ | async)?.totalElements"
    (pageChange)="onPageChange($event)"
  >
  </app-pagination>
</div>
